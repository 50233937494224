import React from 'react';
import { Flex, Heading } from 'rebass/styled-components';
import { useQuery } from '@apollo/client';
import Layout from '../components/Layout';
import getGoodToKnow from '../graphql/queries/getGoodToKnow.graphql';
import SingleNewsItem from '../components/SingleNewsItem';
import Loader from '../components/Loader';

const NewsGoodToKnow = ({ category }) => {
  const { data, loading } = useQuery(getGoodToKnow, {
    variables: {
      category,
    },
  });
  if (loading && !data) {
    return <Loader />;
  }
  return (
    <Layout>
      <Flex variant={'wrapper'} flexDirection={'column'} color={'text'}>
        <Heading
          marginLeft={20}
          mb={[25, 50]}
          fontSize={[24, 32]}
          fontFamily={'Oswald'}
          color={'text'}
        >
          Warto wiedzieć
        </Heading>
        {data.getGoodToKnow.map((goodToKnowItem) => {
          return (
            <SingleNewsItem
              key={goodToKnowItem._id}
              singleNews={goodToKnowItem}
              newsUrl={`/warto-wiedziec/artykul/${goodToKnowItem.slug}`}
            />
          );
        })}
      </Flex>
    </Layout>
  );
};

export default NewsGoodToKnow;
