import React from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Text,
} from 'rebass/styled-components';
import Link from 'gatsby-link';
import * as PropTypes from 'prop-types';

export default function SingleNewsItem(props) {
  const { imageSrc, slug, title } = props.singleNews;
  const newsText = `${props.singleNews.text
    .replace(/<(?:.|\n)*?>/gm, ' ')
    .substr(0, 200)}...`;
  return (
    <Flex
      flexDirection={'column'}
      key={slug}
      marginBottom={40}
      sx={{
        border: '1px solid #DEDEDE',
        borderRadius: 10,
      }}
    >
      <Box m={20}>
        <Flex flexDirection={'row'} alignItems={'center'}>
          <Flex flex={1}>
            <Image src={imageSrc} />
          </Flex>
          <Flex flex={5} flexDirection="column" paddingLeft={20}>
            <Heading
              flexWrap={'wrap'}
              fontSize={[22, 28]}
              fontFamily={'Oswald'}
              fontWeight={'normal'}
            >
              {title}
            </Heading>
          </Flex>
        </Flex>
        <Flex marginY={20}>
          <Text fontFamily={'OpenSans'} fontSize={20}>
            {newsText}
          </Text>
        </Flex>
        <Link to={props.newsUrl}>
          <Button marginTop={20} backgroundColor={'primary'}>
            Zobacz więcej
          </Button>
        </Link>
      </Box>
    </Flex>
  );
}
SingleNewsItem.propTypes = {
  singleNews: PropTypes.object.isRequired,
  newsText: PropTypes.string.isRequired,
  newsUrl: PropTypes.string.isRequired,
};
